import {
  HEADSUP_DATA_FETCH,
  HEADSUP_DATA_RECEIVED,
  HEADSUP_DATA_ERROR,
} from '../actions/types';

const defaultState={
  ready: false,
  loading: false,
  error: null,

  // Minimal data required to render the Movements
  Headsup: [],
};

function headsupReducer(state = defaultState, action) {
  switch (action.type) {
    case HEADSUP_DATA_FETCH:
      return {
        ...state,
        loading: action.status,
        error: null,
        fetched: true,
      };

    case HEADSUP_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Headsup: action.data,
      };

    case HEADSUP_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    default:
      break;
  }

  return state;
}

export default headsupReducer;
