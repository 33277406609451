import {
  COMPANIES_DATA_RECEIVED,
  COMPANIES_DATA_FETCH,
  COMPANIES_DATA_ERROR,
  COMPANY_DATA_DELETE,
  COMPANY_DATA_EDIT,
  COMPANY_DATA_ADD,
  COMPANIES_ERROR_RESET,
  COMPANIES_NEXT_SET_RECEIVED,
} from '../actions/types';
import { append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,
  // Minimal data required to render companies view in sane state.
  Companies: [],
};

function editCompany(state, data) {
  let newArr = [...state];
  for(let i = 0; i < newArr.length; i++) {
    if(newArr[i].ID === data.id) {
      newArr[i] = {...newArr[i], ...data.newValue};
    }
  }
  return newArr;
}

function addCompany(state, company) {
  let newArr = state;
  if(company.ID) newArr.push(company);
  return newArr;
}

function companiesReducer(state = defaultState, action) {
  switch (action.type) {
    case COMPANIES_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case COMPANIES_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Companies: action.data.data,
        count: action.data.count,
      };
    
    case COMPANIES_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Companies: append(state.Companies, action.data.data),
        count: action.data.count,
      };

    case COMPANIES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case COMPANY_DATA_DELETE:
      return {
        ...state,
        error: null,
        Companies: state.Companies.filter(company => !action.data.includes(company.ID)),
        count: state.count - action.data.length,
      };

    case COMPANY_DATA_EDIT:
      return {
        ...state,
        error: null,
        Companies: editCompany(state.Companies, action.data),
      };
    
    case COMPANY_DATA_ADD:
      return {
        ...state,
        error: null,
        Companies: addCompany(state.Companies, action.data),
        count: state.count + 1,
      };
    
    case COMPANIES_ERROR_RESET:
      return {
        ...state,
        error: null,
      };

    default:
      break;
  }

  return state;
}

export default companiesReducer;
