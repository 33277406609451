import {
  SETTINGS_CHANGE, SETTINGS_RECEIVED, SETTINGS_FETCH,
} from '../actions/types';

const defaultState = {
  loading: false,
  devMode: false,
  lang: 'en-US',
  dateFormat: 'L',
  showPending: true,
  fetched: false,
  pageElements: 100,
};

function settingsReducer(state = defaultState, action) {
  switch(action.type) {
    case SETTINGS_CHANGE: 
      return {
        ...state,
        ...action.settings,
      };
    
    case SETTINGS_FETCH:
      return {
        ...state,
        loading: true,
        fetched: false,
      };

    case SETTINGS_RECEIVED: 
      return {
        ...state,
        ...action.settings.settings,
        loading: false,
        fetched: true,
      };

    
    default: {
      break;
    }

  }
  return state;
}

export default settingsReducer;