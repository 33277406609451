import {
  PROPERTIES_DATA_RECEIVED,
  PROPERTIES_DATA_FETCH,
  PROPERTIES_DATA_ERROR,
  PROPERTY_DATA_ADD,
  PROPERTIES_DATA_DELETE,
  PROPERTY_DATA_EDIT,
  PROPERTIES_NEXT_SET_RECEIVED,
} from '../actions/types';
import { addItem, append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,

  // Minimal data required to render properties view in sane state.
  Properties: [],
};

function editItem(state, data) {
  let newArr = state;
  for(let i = 0; i < newArr.length; i++) {
    if(newArr[i].ID === data.id) {
      newArr[i] = {...newArr[i], ...data.newValue};
    }
  }
  return newArr;
}

function propertiesReducer(state = defaultState, action) {
  switch (action.type) {
    case PROPERTIES_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case PROPERTIES_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Properties: action.data.data ? action.data.data : action.data,
        count: action.data.count || 0,
      };

    case PROPERTIES_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Properties: append(state.Properties, action.data.data ? action.data.data : action.data),
        count: action.data.count || 0,
      };

    case PROPERTIES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };
    
    case PROPERTY_DATA_ADD:
      return {
        ...state,
        Properties: addItem(state.Properties, action.data),
        count: state.count + 1,
      };

    case PROPERTY_DATA_EDIT:
      return {
        ...state,
        Properties: editItem(state.Properties, action.data),
      };

    case PROPERTIES_DATA_DELETE:
      return {
        ...state,
        Properties: state.Properties.filter(item => !action.IDs.includes(item.ID)),
        count: state.count - action.IDs.length,
      };

    default:
      break;
  }

  return state;
}

export default propertiesReducer;
