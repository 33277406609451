export const AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING';
export const AUTH_AUTHENTICATED = 'AUTH_AUTHENTICATED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGON = 'AUTH_LOGON';
export const AUTH_LOGON_ERROR = 'AUTH_LOGON_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const ARTICLES_DATA_FETCH = 'ARTICLES_DATA_FETCH';
export const ARTICLES_DATA_RECEIVED = 'ARTICLES_DATA_RECEIVED';
export const ARTICLES_DATA_ERROR = 'ARTICLES_DATA_ERROR';
export const ARTICLES_DATA_RESET = 'ARTICLES_DATA_RESET';
export const ARTICLE_DATA_DELETE = 'ARTICLE_DATA_DELETE';
export const ARTICLE_DATA_EDIT = 'ARTICLE_DATA_EDIT';
export const ARTICLES_NEXT_SET_RECEIVED = 'ARTICLES_NEXT_SET_RECEIVED';

export const COMPANIES_DATA_FETCH = 'COMPANIES_DATA_FETCH';
export const COMPANIES_DATA_RECEIVED = 'COMPANIES_DATA_RECEIVED';
export const COMPANIES_NEXT_SET_RECEIVED = 'COMPANIES_NEXT_SET_RECEIVED';
export const COMPANIES_DATA_ERROR = 'COMPANIES_DATA_ERROR';
export const COMPANY_DATA_ADD = 'COMPANY_DATA_ADD';
export const COMPANY_DATA_DELETE = 'COMPANY_DATA_DELETE';
export const COMPANY_DATA_EDIT = 'COMPANY_DATA_EDIT';
export const COMPANIES_ERROR_RESET = 'COMPANY_ERROR_RESET';

export const DRAWER_OPEN_DEFAULT = 'DRAWER_OPEN_DEFAULT';
export const DRAWER_CLOSE_DEFAULT = 'DRAWER_CLOSE_DEFAULT';
export const DRAWER_EXPAND = 'DRAWER_EXPAND';
export const DRAWER_CLOSE = 'DRAWER_CLOSE';
export const DRAWER_SELECTION = 'DRAWER_SELECTION';

export const GROUPS_DATA_FETCH = 'GROUPS_DATA_FETCH';
export const GROUPS_DATA_RECEIVED = 'GROUPS_DATA_RECEIVED';
export const GROUPS_NEXT_SET_RECEIVED = 'GROUPS_NEXT_SET_RECEIVED';
export const GROUPS_DATA_ERROR = 'GROUPS_DATA_ERROR';
export const GROUP_DATA_ADD = 'GROUP_DATA ADD';
export const GROUP_DATA_DELETE = 'GROUP_DATA_DELETE';
export const GROUP_DATA_EDIT = 'GROUP_DATA_EDIT';

export const HEADSUP_DATA_FETCH = 'HEADSUP_DATA_FETCH';
export const HEADSUP_DATA_RECEIVED = 'HEADSUP_DATA_RECEIVED';
export const HEADSUP_DATA_ERROR = 'HEADSUP_DATA_ERROR';

export const LOCATIONS_DATA_FETCH = 'LOCATIONS_DATA_FETCH';
export const LOCATIONS_DATA_RECEIVED = 'LOCATIONS_DATA_RECEIVED';
export const LOCATIONS_NEXT_SET_RECEIVED = 'LOCATIONS_NEXT_SET_RECEIVED';
export const LOCATIONS_DATA_ERROR = 'LOCATIONS_DATA_ERROR';
export const LOCATIONS_SUGGESTIONS_GET = 'LOCATIONS_SUGGESTIONS_GET';
export const LOCATIONS_SUGGESTIONS_RESET = 'LOCATIONS_SUGGESTIONS_RESET';
export const LOCATION_DATA_ADD = 'LOCATION_DATA_ADD';
export const LOCATION_DATA_DELETE = 'LOCATION_DATA_DELETE';
export const LOCATION_DATA_EDIT = 'LOCATION_DATA_EDIT';

export const MODELS_DATA_FETCH = 'MODELS_DATA_FETCH';
export const MODELS_DATA_RECEIVED = 'MODELS_DATA_RECEIVED';
export const MODELS_DATA_ERROR = 'MODELS_DATA_ERROR';
export const MODEL_DATA_ADD = 'MODEL_DATA_ADD';
export const MODEL_DATA_DELETE = 'MODELS_DATA_DELETE';
export const MODEL_DATA_EDIT = 'MODELS_DATA_EDIT';
export const MODELS_NEXT_SET_RECEIVED = 'MODELS_NEXT_SET_RECEIVED';

export const MOVEMENTS_DATA_FETCH = 'MOVEMENTS_DATA_FETCH';
export const MOVEMENTS_DATA_RECEIVED = 'MOVEMENTS_DATA_RECEIVED';
export const MOVEMENTS_DATA_ERROR = 'MOVEMENTS_DATA_ERROR';

export const MOVEMENT_TYPES_DATA_FETCH = 'MOVEMENT_TYPES_DATA_FETCH';
export const MOVEMENT_TYPES_DATA_RECEIVED = 'MOVEMENT_TYPES_DATA_RECEIVED';
export const MOVEMENT_TYPES_NEXT_SET_RECEIVED = 'MOVEMENT_TYPES_NEXT_SET_RECEIVED';
export const MOVEMENT_TYPES_DATA_ERROR = 'MOVEMENT_TYPES_DATA_ERROR';
export const MOVEMENT_TYPES_DATA_DELETE = 'MOVEMENT_TYPES_DATA_DELETE';
export const MOVEMENT_TYPES_DATA_ADD = 'MOVEMENT_TYPES_DATA_ADD';

export const PEOPLE_DATA_FETCH = 'PEOPLE_DATA_FETCH';
export const PEOPLE_DATA_RECEIVED = 'PEOPLE_DATA_RECEIVED';
export const PEOPLE_NEXT_SET_RECEIVED = 'PEOPLE_NEXT_SET_RECEIVED';
export const PEOPLE_DATA_ERROR = 'PEOPLE_DATA_ERROR';
export const PERSON_DATA_ADD = 'PERSON_DATA_ADD';
export const PERSON_DATA_DELETE = 'PERSON_DATA_DELETE';
export const PERSON_DATA_EDIT = 'PERSON_DATA_EDIT';
export const PERSON_DATA_RECEIVED = 'PERSON_DATA_RECEIVED';

export const PROFILE_DATA_FETCH = 'PROFILE_DATA_FETCH';
export const PROFILE_DATA_RECEIVED = 'PROFILE_DATA_RECEIVED';
export const PROFILE_DATA_ERROR = 'PROFILE_DATA_ERROR';
export const PROFILE_USER_RECEIVED= 'PROFILE_USER_RECEIVED';

export const PROPERTIES_DATA_FETCH = 'PROPERTIES_DATA_FETCH';
export const PROPERTIES_DATA_RECEIVED = 'PROPERTIES_DATA_RECEIVED';
export const PROPERTIES_NEXT_SET_RECEIVED = 'PROPERTIES_NEXT_SET_RECEIVED';
export const PROPERTIES_DATA_ERROR = 'PROPERTIES_DATA_ERROR';
export const PROPERTIES_DATA_DELETE = 'PROPERTIES_DATA_DELETE';
export const PROPERTY_DATA_ADD = 'PROPERTY_DATA_ADD';
export const PROPERTY_DATA_EDIT = 'PROPERTY_DATA_EDIT';

export const SETTINGS_CHANGE = 'SETTINGS_CHANGE';
export const SETTINGS_FETCH = 'SETTINGS_FETCH';
export const SETTINGS_RECEIVED = 'SETTINGS_RECEIVED';

export const TODO_ERROR = 'TODO_ERROR';
export const TODO_FETCH = 'TODO_FETCH';
export const TODO_RECEIVED = 'TODO_RECEIVED';
export const TODO_NEXT_SET_RECEIVED = 'TODO_NEXT_SET_RECEIVED';
export const TODO_DELETE = 'TODO_DELETE';

export const TYPES_DATA_FETCH = 'TYPES_DATA_FETCH';
export const TYPES_DATA_RECEIVED = 'TYPES_DATA_RECEIVED';
export const TYPES_DATA_ERROR = 'TYPES_DATA_ERROR';
