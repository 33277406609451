import {
  LOCATIONS_DATA_RECEIVED,
  LOCATIONS_DATA_FETCH,
  LOCATIONS_DATA_ERROR,
  LOCATIONS_SUGGESTIONS_GET,
  LOCATIONS_SUGGESTIONS_RESET,
  LOCATION_DATA_ADD,
  LOCATION_DATA_DELETE,
  LOCATIONS_NEXT_SET_RECEIVED,
} from '../actions/types';
import { addItem, append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,

  // Minimal data required to render locations view in sane state.
  Suggestions: [],
  Locations: [],
};

function locationsReducer(state = defaultState, action) {
  switch (action.type) {
    case LOCATIONS_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LOCATIONS_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Locations: action.data.data,
        count: action.data.count,
      };

    case LOCATIONS_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Locations: append(state.Locations, action.data.data),
        count: action.data.count,
      };

    case LOCATIONS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case LOCATION_DATA_DELETE:
      return {
        ...state,
        Locations: state.Locations.filter(location => !action.data.includes(location.ID)),
        count: state.count - action.data.length,
      };

    case LOCATION_DATA_ADD:
      return {
        ...state,
        Locations: addItem(state.Locations, action.data),
        count: state.count + 1,
      };

    case LOCATIONS_SUGGESTIONS_GET:
      return {
        ...state,
        Suggestions: action.suggestions,
      };
    
    case LOCATIONS_SUGGESTIONS_RESET:
      return {
        ...state,
        Suggestions: [],
      };
    
    default:
      break;
  }

  return state;
}

export default locationsReducer;
