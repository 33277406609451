const grey = {
  A100: '#f0f0f0',
  A200: '#cdcdcd',
  A400: '#969696',
  A700: '#737373',
  A900: '#323232',
  900: '#212121',
  300: '#e0e0e0',
  50: '#fafafa',
  //A200:
  //A400:
  //A700:
};

export default grey;
