import {
  PEOPLE_DATA_RECEIVED,
  PEOPLE_DATA_FETCH,
  PEOPLE_DATA_ERROR,
  PERSON_DATA_ADD,
  PERSON_DATA_DELETE,
  PERSON_DATA_EDIT,
  PEOPLE_NEXT_SET_RECEIVED,
} from '../actions/types';
import { addItem, append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,

  // Minimal data required to render locations view in sane state.
  People: [],
};

function editPerson(state, data) {
  let newArr = state;
  for(let i = 0; i < newArr.length; i++) {
    if(newArr[i].ID === data.id) {
      newArr[i].name = data.newValue;
    }
  }
  return newArr;
}

function peopleReducer(state = defaultState, action) {
  switch (action.type) {
    case PEOPLE_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case PEOPLE_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        People: action.data.data,
        count: action.data.count,
      };

    case PEOPLE_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        People: append(state.People, action.data.data),
        count: action.data.count,
      };

    case PEOPLE_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case PERSON_DATA_DELETE:
      return {
        ...state,
        People: state.People.filter(person => !action.data.includes(person.ID)),
        count: state.count - action.data.length,
      };
    
    case PERSON_DATA_EDIT:
      return {
        ...state,
        People: editPerson(state.People, action.data),
      };

    case PERSON_DATA_ADD:
      return {
        ...state,
        People: addItem(state.People, action.data),
        count: state.count + 1,
      };

    default:
      break;
  }

  return state;
}

export default peopleReducer;
