import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  "en-US": {
    translation: {
      "addTextfield": "Enter new {{item}}",
      "addItemText": "Enter {{item}}",
      "addHeadline": "Add {{item}}",
      "editHeadline": "Edit {{item}}",
      "type": "Type",
      "manufacturer": "Manufacturer",
      "model": "Model",
      "supplier": "Supplier",
      "manufacturerSerial": "Serialnumber",
      "inventory": "Inventorynumber",
      "location": "Location",
      "purchaseDate": "Purchase Date",
    },
  },
  "de-DE": {
    translation: {
      "No": "Nein",
      "Yes": "Ja",
      "Search location": "Standort suchen",
      "Search group": "Gruppe suchen",
      "Search movement type": "Bewegungsart suchen",
      "Search company": "Firma suchen",
      "Search person": "Person suchen",
      "Search model": "Modell suchen",
      "Search asset": "Asset suchen",
      "Search task": "Aufgabe suchen",
      "Add article": "Artikel hinzufügen",
      "Add asset": "Asset hinzufügen",
      "Move asset": "Asset bewegen",
      "Add user": "Benutzer hinzufügen",
      "Add group": "Gruppe hinzufügen",
      "Actions": "Aktionen",
      "Last articles": "Letzte Artikel",
      "Done": "Erledigt",
      "Check": "Abhaken",
      "Pending": "Ausstehend",
      "Pending activities": "Anstehende Aktivitäten",
      "Menu": "Menü",
      "Articles": "Artikel",
      "Article Group": "Artikel Gruppe",
      "Article": "Artikel",
      "Default data": "Stammdaten",
      "Settings": "Einstellungen",
      "Groups": "Gruppen",
      "Article groups": "Artikel Gruppen",
      "Movement types": "Bewegungsarten",
      "Movement Types": "Bewegungsarten",
      "Movement type": "Bewegungsart",
      "Movement type details": "Bewegungsart details",
      "Location": "Standort",
      "Locations": "Standorte",
      "Companies": "Firmen",
      "People": "Personen",
      "Parent": "Eltern",
      "Task Details": "Aufgabendetails",
      "Task": "Aufgabe",
      "Description": "Beschreibung",
      "Date": "Datum",
      "Type": "Typ",
      "type": "Typ",
      "Name": "Name",
      "First name": "Vorname",
      "Last name": "Nachname",
      "My assets": "Meine Assets",
      "My activities": "Meine aktivitäten",
      "Information": "Information",
      "Inventory": "Inventar",
      "Model": "Modell",
      "Models": "Modelle",
      "model": "Modell",
      "Serialnumber": "Seriennummer",
      "manufacturerSerial": "Seriennummer",
      "manufacturer": "Hersteller",
      "Manufacturer": "Hersteller",
      "Purchase date": "Kaufdatum",
      "Delivery date": "Lieferdatum",
      "Warranty start": "Garantiebeginn",
      "Warranty end": "Garantieende",
      "Save changes": "Änderungen speichern",
      "Supplier": "Lieferant",
      "supplier": "Lieferant",
      "Comment": "Kommentar",
      "Properties": "Eigenschaften",
      "Property": "Eigenschaft",
      "Movements": "Bewegungen",
      "Details": "Details",
      "Darkmode": "Nachtmodus",
      "Dogemode": "Nacktmodus", // Easteregg in dev mode, dont worry about it
      "Location details": "Standort Details",
      "Model details": "Modell details",
      "Group details": "Gruppen Details",
      "Needs inventory number": "Benötigt Inventarnummer",
      "Inventory number": "Inventarnummer",
      "Inventory Number": "Inventarnummer",
      "Needs user": "Benötigt Benutzer",
      "Needs location": "Benötigt Standort",
      "Is system type": "Ist Systemtyp",
      "Free stock": "Freier Bestand",
      "Locked": "Gesperrt",
      "Retired": "Im Ruhestand",
      "Read only": "Schreibgeschützt",
      "Producer": "Hersteller",
      "Overview": "Übersicht",
      "Assign": "Zuweisen",
      "Add": "Hinzufügen",
      "Delete": "Löschen",
      "Add files": "Dateien hinzufügen",
      "Show scrapped": "Gelöschte anzeigen",
      "End": "Ende",
      "Begin": "Anfang",
      "Start": "Anfang",
      "Back": "Zurück",
      "Next": "Weiter",
      "Finish": "Fertig",
      "Value": "Wert",
      "addTextfield": "Neue $t({{item}}) hinzufügen",
      "Enter end date": "Ende eingeben",
      "Enter start date": "Beginn eingeben",
      "addItemText": "$t({{item}}) Eingeben",
      "Enter new value": "Neuen Wert eingeben",
      "addHeadline": "$t({{item}}) hinzufügen",
      "editHeadline": "$t({{item}}) ändern",
      "Language": "Sprache",
      "Scan barcode": "Barcode scannen",
      "New barcode": "Neuer Barcode",
      "Inventorynumber": "Inventarnummer",
      "inventoryNumber": "Inventarnummer",
      "Support end": "Unterstützungsende",
      "by": 'von',
      "Group name": "Gruppenname",
      "Entered": "Eingeben",
      "Entered by": "Eingeben von",
      "Select Date": "Datum wählen",
      "Username": "Benutzername",
      "User": "Benutzer",
      "Password": "Passwort",
      "Login": "Anmelden",
      "Logout": "Abmelden",
      "Asset groups": "Assetgruppen",
      "Last assets": "Letzte Assets",
      "Enter ID": 'ID eingeben',
      "Search": "Suchen",
      "Located at": "Liegt bei",
      "Set": "Setzen",
      "Default location": "Stammort",
      "List": "Liste",
      "Hirarchie": "Hirarchie",
      "Add person": "Person hinzufügen",
      "Enter first name": "Vornamen eingeben",
      "Enter last name": "Nachnamen eingeben",
      "Are you sure you want to cancel?": "Wollen Sie wirklich abbrechen?",
      "Cancel": "Abbrechen",
      "Are you sure you want to delete this asset?": "Wollen Sie dieses Asset wirklich löschen?",
      "Use default": "Stammort verwenden",
      "(optional)": "(optional)",
      "location": "Standort",
      "inventory": "Inventarnummer",
      "purchaseDate": "Kaufdatum",
      "Go back": "Zurück",
      "Send to database": "An Datenbank schicken",
      "Skip this step": "Diesen Schritt überspringen",
      "Apply": "Übernehmen",
      "Show pending activities": "Anstehende Aktivitäten anzeigen",
      "Developer mode": "Entwicklermodus",
      "Failed to login. Incorrect password or username": "Login fehlgeschlagen. Passwort oder Nutzername falsch",
      "Elements per page": "Maximale Tabellenzeilen pro Seite",
      "Date format": "Datumsformat",
      "Profile": "Profil",
      "Upload image": "Bild Hochladen",
      "Add Company": "Firma hinzufügen",
      "Is manufacturer": "Ist Hersteller",
      "Is supplier": "Ist Zulieferer",
      "Company details": "Firmen Details",
      "Choose picture": "Bild auswählen",
      "Table": "Tabelle",
      "Hierarchy": "Hierarchie",
      "Children": "Kinder",
      "Icon": "Symbol",
      "Needs Location": "Benötigt Standort",
      "Person details": "Personen Details",
      "Choose option": "Option auswählen",
      "Files": "Dateien",
      "Upload file": "Datei hochladen",
      "Add reminder": "Erinnerung hinzufügen",
      "Associated models": "Zugehörige Modelle",
      "Related assets": "Zugehörige Assets",
      "Pool size": "Pool Größe",
      "Pool supplier": "Pool Lieferant",
      "Pool purchase date": "Pool Kaufdatum",
      "Pool used": "In Gebrauch",
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: false, 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });


export default i18n;