import {
  TODO_ERROR,
  TODO_FETCH,
  TODO_RECEIVED,
  TODO_DELETE,
  TODO_NEXT_SET_RECEIVED,
} from '../actions/types';
import { append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,

  Todo: [],
};

function todoReducer(state = defaultState, action) {
  switch(action.type) {
    case TODO_FETCH:
      return {
        ...state,
        ready: false,
        loading: true,
      };

    case TODO_RECEIVED:
      return {
        ...state,
        loading: false,
        ready: true,
        Todo: action.data.data,
        count: action.data.count,
      };

    case TODO_NEXT_SET_RECEIVED:
      return {
        ...state,
        loading: false,
        ready: true,
        Todo: append(state.Todo, action.data.data),
        count: action.data.count,
      };

    case TODO_ERROR:
      return {
        ...state,
        loading: false,
        ready: false,
        error: action.error,
      };

    case TODO_DELETE:
      return {
        ...state,
        Todo: state.Todo.filter(item => !action.data.includes(item.ID)),
        count: state.count - action.data.length,
      };

    default: break;
  }

  return state;
}

export default todoReducer;
