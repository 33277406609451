import {
  MODELS_DATA_RECEIVED,
  MODELS_DATA_FETCH,
  MODELS_DATA_ERROR,
  MODEL_DATA_DELETE,
  MODEL_DATA_EDIT,
  MODEL_DATA_ADD,
  MODELS_NEXT_SET_RECEIVED,
} from '../actions/types';
import { addItem } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  finished: false,
  count: 0,

  // Minimal data required to render model view in sane state.
  Models: [],
};

function append(arr, newArr) {
  let copy = [...arr];
  copy = copy.concat(newArr);
  return copy;
}

function editModel(state, data) {
  let newArr = state;
  for(let i = 0; i < newArr.length; i++) {
    if(newArr[i].ID === data.id) {
      newArr[i] = {...newArr[i], ...data.newValue};
    }
  }
  return newArr;
}

function modelsReducer(state = defaultState, action) {
  switch (action.type) {
    case MODELS_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case MODELS_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Models: action.data.data,
        count: action.data.count,
      };

    case MODELS_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Models: append(state.Models, action.data.data),
        count: action.data.count,
      };

    case MODELS_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case MODEL_DATA_ADD:
      return {
        ...state,
        Models: addItem(state.Models, action.data),
        count: state.count + 1,
      };

    case MODEL_DATA_DELETE:
      return {
        ...state,
        Models: state.Models.filter(group => !action.data.includes(group.ID)),
        count: state.count - action.data.length,
      };

    case MODEL_DATA_EDIT:
      return {
        ...state,
        error: null,
        Models: editModel(state.Models, action.data),
      };

    default:
      break;
  }

  return state;
}

export default modelsReducer;
