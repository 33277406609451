import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';

import thunkMiddleware from 'redux-thunk';

import authReducer from './reducers/auth';
import movementsReducer from './reducers/movements';
import headsupReducer from './reducers/headsup';
import articlesReducer from './reducers/articles';
import groupsReducer from './reducers/groups';
import drawerReducer from './reducers/drawer';
import locationsReducer from './reducers/locations';
import companiesReducer from './reducers/companies';
import modelsReducer from './reducers/models';
import movementTypesReducer from './reducers/mTypes';
import peopleReducer from './reducers/people';
import profileReducer from './reducers/profile';
import propertiesReducer from './reducers/properties';
import settingsReducer from './reducers/settings';
import todoReducer from './reducers/todo';

const loggerMiddleware = createLogger();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  combineReducers({
    articles: articlesReducer,
    auth: authReducer,
    companies: companiesReducer,
    drawer: drawerReducer,
    groups: groupsReducer,
    headsup: headsupReducer,
    locations: locationsReducer,
    models: modelsReducer,
    movements: movementsReducer,
    movementTypes: movementTypesReducer,
    people: peopleReducer,
    profile: profileReducer,
    properties: propertiesReducer,
    settings: settingsReducer,
    todo: todoReducer,
  }),
  composeEnhancers(applyMiddleware(
    thunkMiddleware,

    loggerMiddleware // Must be last.
  ))
);

export default store;
