import { createMuiTheme } from '@material-ui/core/styles';

import blue from './colors/blue';
import brightBlue from './colors/brightBlue';
import grey from './colors/grey';

const shadowKeyUmbraOpacity = 0.2;
const shadowKeyPenumbraOpacity = 0.14;
const shadowAmbientShadowOpacity = 0.12;
const shadowShade = 100;

function createShadow(...px) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(${shadowShade}, ${shadowShade}, ${shadowShade}, ${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(${shadowShade}, ${shadowShade}, ${shadowShade}, ${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(${shadowShade}, ${shadowShade}, ${shadowShade}, ${shadowAmbientShadowOpacity})`,
  ].join(',');
}

const shadows = [
  'none',
  createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
  createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
  createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];

const mode = window.localStorage.getItem('darkMode') === 'true' ? 'dark' : 'light';

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      positionAbsolute: {
        boxShadow: 'none',
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: mode === 'light' ? grey['50'] : '#303030',
      },
      head: {
        backgroundColor: mode === 'light' ? grey['50'] : '#303030',
      },
    },
    MuiTableRow: {
      root: {
        "&:nth-child(odd)": {
          backgroundColor: mode === 'light' ? '#f0f0f0' : 'rgba(255, 255, 255, 0.12)',
        },
        '&$hover': {
          "&:hover": {
            backgroundColor: mode === 'light' ? '#e0e0e0' : brightBlue['300'],
          },
        },
        '&$selected': {
          backgroundColor: mode === 'light' ? brightBlue['300'] : brightBlue['600'],
        },
      },
    },
    MuiGridListTile: {
      tile: {
        display: 'flex',
        flex: 1,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
  },
  palette: {
    type: mode,
    background: {
      paper: mode === 'light' ? grey['50'] : '#424242',
      default: mode === 'light' ? grey['300'] : '#303030',
    },
    primary: mode === 'light' ? blue : brightBlue,
    secondary: {
      light: grey.A400,
      main: grey.A700,
      dark: grey.A700,
    },
    contrastThreshold: 2,
    tonalOffset: 0.1,
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Roboto',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(', '),
    fontSize: 14,
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
  },
  shadows,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
