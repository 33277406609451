import {
  ARTICLES_DATA_RECEIVED,
  ARTICLES_DATA_FETCH,
  ARTICLES_DATA_ERROR,
  ARTICLES_DATA_RESET,
  ARTICLE_DATA_DELETE,
  ARTICLES_NEXT_SET_RECEIVED,
  ARTICLE_DATA_EDIT,
} from '../actions/types';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  finished: false,
  count: 0,
  // Minimal data required to render articles view in sane state.
  Articles: [],
};

function append(arr, newArr) {
  let copy = [...arr];
  copy = copy.concat(newArr);
  return copy;
}

function editArticle(articles, input) {
  let articlesCopy = articles;
  for (let i = 0; i < articlesCopy.length; i++) {
    if (articlesCopy[i].ID === input.id) {
      articlesCopy[i] = {...articlesCopy[i], ...input.data};
    }
  }
  return articlesCopy;
}

function articlesReducer(state = defaultState, action) {
  switch (action.type) {
    case ARTICLES_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ARTICLES_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Articles: action.data.data,
        count: action.data.count,
      };

    case ARTICLES_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Articles: append(state.Articles, action.data.data),
        count: action.data.count,
      };

    case ARTICLES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case ARTICLES_DATA_RESET: {
      return {
        ...state,
        error: null,
        Articles: [],
      };
    }

    case ARTICLE_DATA_EDIT: {
      return {
        ...state,
        error: null,
        Articles: editArticle(state.Articles, action.data),
      };
    }

    case ARTICLE_DATA_DELETE:
      return {
        ...state,
        Articles: state.Articles.filter(article => !action.data.includes(article.ID)),
        count: state.count - action.data.length,
      };
    
    default:
      break;
  }

  return state;
}

export default articlesReducer;
