import {
  PROFILE_DATA_FETCH,
  PROFILE_DATA_ERROR,
  PROFILE_DATA_RECEIVED,
  PROFILE_USER_RECEIVED,
} from '../actions/types';

const defaultState = {
  loading: false,
  error: null,
  ready: false,

  Profile: {
    articles: [],
    movements: [],
    tasks: [],
    user: {
      person: {},
    },
  },
};

function profileReducer(state = defaultState, action) {
  switch(action.type) {
    case PROFILE_DATA_FETCH:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_DATA_RECEIVED:
      return {
        ...state,
        loading: false,
        ready: true,
        error: null,
        //Needs to adapt when api call is changed
        Profile: action.data,
      };

    case PROFILE_USER_RECEIVED:
      return {
        ...state,
        Profile: {
          ...state.Profile,
          user: {
            ...state.Profile.user,
            person: action.person ? action.person : {},
          },
        },
      };

    case PROFILE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        ready: false,
        error: action.error,
      };

    default: return state;
  }
}

export default profileReducer;