import {
  MOVEMENT_TYPES_DATA_RECEIVED,
  MOVEMENT_TYPES_DATA_FETCH,
  MOVEMENT_TYPES_DATA_ERROR,
  MOVEMENT_TYPES_DATA_DELETE,
  MOVEMENT_TYPES_DATA_ADD,
  MOVEMENT_TYPES_NEXT_SET_RECEIVED,
} from '../actions/types';
import { addItem, append } from '../utils';

const defaultState={
  ready: false,
  loading: false,
  error: null,
  count: 0,
  Types: [],
};

function movementTypesReducer(state = defaultState, action) {
  switch (action.type) {
    case MOVEMENT_TYPES_DATA_FETCH:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case MOVEMENT_TYPES_DATA_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Types: action.data.data,
        count: action.data.count,
      };

    case MOVEMENT_TYPES_NEXT_SET_RECEIVED:
      return {
        ...state,
        ready: true,
        loading: false,
        error: null,
        Types: append(state.Types, action.data.data),
        count: action.data.count,
      };
        
    case MOVEMENT_TYPES_DATA_ERROR:
      return {
        ...state,
        ready: false,
        loading: false,
        error: action.err,
      };

    case MOVEMENT_TYPES_DATA_ADD:
      return {
        ...state,
        Types: addItem(state.Types, action.data),
        count: state.count + 1,
      };
      
    case MOVEMENT_TYPES_DATA_DELETE:
      return {
        ...state,
        Types: state.Types.filter(type => !action.data.includes(type.ID)),
        count: state.count - action.data.length,
      };
    
    default:
      break;
  }

  return state;
}

export default movementTypesReducer;
