const brightBlue = {
  100: '#b3e5fc',
  200: '#81d4fa',
  300: 'rgba(79, 194, 247, 0.6)',
  400: 'rgba(41, 182, 246, 0.9)',
  500: '#23daf9',
  600: 'rgba(3, 155, 229, 0.8)',
  //700:
  //A200:
  //A400:
  //A700:
};

export default brightBlue;